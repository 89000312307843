import React, { useState } from 'react'
import { LanguageSelector } from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import menuIcon from '../images/menu.png';

function Header() {
  const {t} = useTranslation("common");
  const [opened, setOpened] = useState(false);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setOpened(false);
    }
  };
  
  return (
    <div>
      <nav className=' bg-black p-4 md:flex justify-between pr-12 hidden h-28'>
          <ul className='flex flex-row items-center gap-8 lg:gap-16 text-2xl font-bold text-yellow-50' >
              <li><img src={require('../images/vnb.png')} className='h-20 cursor-pointer' onClick={() => scrollToElement('page')}/></li>
              <li className=' cursor-pointer hover:text-yellow-400' onClick={() => scrollToElement('who-we-are')}>{t('Kto sme')}</li>
              <li className=' cursor-pointer hover:text-yellow-400' onClick={() => scrollToElement('job-offers')}>{t('Pracovné ponuky')}</li>
              <li className=' cursor-pointer hover:text-yellow-400' onClick={() => scrollToElement('contact')}>{t('Kontakt')}</li>
          </ul>
          <LanguageSelector className={"flex gap-4 items-center justify-center text-white"}/>
      </nav>
      <nav className=' bg-black flex flex-col justify-between w-full z-50 md:hidden'>
          <div className='navbar flex justify-between items-center h-28 px-8'>
            <div><img src={require('../images/vnb.png')} className='h-20 cursor-pointer' onClick={() => scrollToElement('page')}/></div>
            <div onClick={() => setOpened(!opened)} className=' text-white cursor-pointer'><img className=' h-6' src={menuIcon}/></div>
          </div>
          {opened &&
            <div className='absolute p-4 w-full bg-black mt-28'>
              <ul className='flex flex-col items-center gap-8 lg:gap-16 text-2xl font-bold text-yellow-50' >
                <li className=' cursor-pointer hover:text-yellow-400' onClick={() => scrollToElement('who-we-are')}>{t('Kto sme')}</li>
                <li className=' cursor-pointer hover:text-yellow-400' onClick={() => scrollToElement('job-offers')}>{t('Pracovné ponuky')}</li>
                <li className=' cursor-pointer hover:text-yellow-400' onClick={() => scrollToElement('contact')}>{t('Kontakt')}</li>
              </ul>
              <LanguageSelector className={"flex gap-4 items-center justify-center mt-8 text-white"}/>
            </div>
          }
      </nav>
    </div>
  )
}

export default Header