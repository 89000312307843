import React from 'react'
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Carosuel({offers}) {
    const {t} = useTranslation("common");
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

  return (
    <div className='w-full md:w-4/5 m-auto text-white'>
        <Carousel responsive={responsive} infinite={true} ssr={true}>
        {offers.map((offer, index) => (
            <div key={index} className=" text-white flex flex-col justify-center text-center w-3/5 m-auto bg-yellow-400 p-2">
                <span className=" text-2xl md:text-4xl text-black font-bold">{offer.name}</span>
                <div className=" grid grid-cols-1 lg:grid-cols-3 mt-4 bg-black p-4 font-mono">
                    <div className=" flex flex-col">
                        <span className=' text-2xl mb-1 text-yellow-400 '>{t('Miesto práce')} :</span>
                        {offer.location.map((location, index) => (
                            <span className=' text-xl' key={index}>{location}</span>
                        ))}
                    </div>
                    <div className=" flex flex-col">
                        <span className=' text-2xl mb-1 text-yellow-400 '>{t("Dátum nástupu")} :</span>
                        <span className=' text-xl'>{offer.startDate}</span>
                    </div>
                    <div className=" flex flex-col">
                        <span className=' text-2xl mb-1 text-yellow-400 '>{t("Druh pracovného pomeru")} :</span>
                        <span className=' text-xl'>{offer.offerType}</span>
                    </div>
                </div>
            </div>
        ))}
        </Carousel>
    </div>
  )
}

export default Carosuel