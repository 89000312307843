import MultipleItems from "./components/CarouselDefault";
import Contact from "./components/Contact";
import Header from "./components/Header";
import About from "./components/About";
import backgroundImage from './images/bg4.png';
import instagramImage from './images/instagram.png';
import facebookImage from './images/facebook.png';
import {useTranslation} from "react-i18next";
import Carosuel from "./components/Carosuel";

function App() {
  const {t} = useTranslation("common");

  const offers = [
    {name: t("Murár"), location: [t("Nemecko"), t("Rakúsko"), t("Holandsko")], startDate: t("Po dohode"), offerType: t("SZČO")},
    {name: t("Elektrikár"), location: [t("Nemecko"), t("Rakúsko"), t("Holandsko")], startDate: t("Po dohode"), offerType: t("SZČO")},
    {name: t("Sadrokartonista"), location: [t("Nemecko"), t("Rakúsko"), t("Holandsko")], startDate: t("Po dohode"), offerType: t("SZČO")},
    {name: t("Lešenár"), location: [t("Nemecko"), t("Rakúsko"), t("Holandsko")], startDate: t("Po dohode"), offerType: t("SZČO")},
    {name: t("Maliar"), location: [t("Nemecko"), t("Rakúsko"), t("Holandsko")], startDate: t("Po dohode"), offerType: t("SZČO")},
    {name: t("Fasádnik"), location: [t("Nemecko"), t("Rakúsko"), t("Holandsko")], startDate: t("Po dohode"), offerType: t("SZČO")},
    {name: t("Obkladač"), location: [t("Nemecko"), t("Rakúsko"), t("Holandsko")], startDate: t("Po dohode"), offerType: t("SZČO")},
    {name: t("Zvárač"), location: [t("Nemecko"), t("Rakúsko"), t("Holandsko")], startDate: t("Po dohode"), offerType: t("SZČO")},
  ]

  return (
    <div id="page" className="bg-cover bg-center h-full flex flex-col font-mono">
      <div  className="bg-cover bg-center h-full" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Header/>
      <div className="flex flex-col p-4 items-center justify-center">
        <About/>
      </div>
      <div id="job-offers" className="flex flex-col items-center my-8">
      <h className='text-4xl font-bold font  text-yellow-400 mb-6' >{t("Pracovné ponuky")}</h>
        {/* <MultipleItems offers={offers} /> */}
        <Carosuel offers={offers} />
      </div>
      <div style={{zIndex:1001}} className="fixed bottom-0 w-full h-12 bg-yellow-400 md:flex items-center justify-between px-8 hidden">
        <div className="flex gap-8">
          <a className=" hover:underline" href="mailto:vnbbau@gmail.com">vnbbau@gmail.com</a>
          <a className=" hover:underline" href="tel:+421 948 571 336">+421 948 571 336</a>
        </div>
        <div className="flex gap-4">
          <a href="https://www.instagram.com/vnbbau/">
            <img className=" w-10 h-10 object-cover" src={instagramImage}/>
          </a>
          <a className="m-auto" href="https://www.facebook.com/profile.php?id=61555669226019">
            <img className=" w-8 h-8 object-cover" src={facebookImage}/>
          </a>
        </div>
      </div>
      <footer style={{zIndex:1010}} className=" flex h-full mb-0 mt-auto relative">
        <Contact/>
      </footer>
      </div>
   </div>
  );
}

export default App;
