import React from 'react'
import { useTranslation } from 'react-i18next';
import constructImg from '../images/construction.jpg';

function About() {
    const {t} = useTranslation("common");
    return (

        <div id="who-we-are" className='flex flex-col mt-28 items-center justify-center text-yellow-50 md:w-3/5'>
            <h className='text-4xl font-mono font-bold text-yellow-400' >{t('KTO SME')}</h>
            <div className=' font-mono text-2xl leading-10 mt-10 text-center flex flex-col gap-6' >
                <p>
                    {t('Vítame vás v našej spoločnosti VNBBAU s.r.o.!')}
                </p>
                <p>
                    {t('Sme pevnou oporou v oblasti stavebníctva a výroby. Naše hlavné aktivity zahŕňajú poskytovanie kvalifikovaných profesionálov pre rôzne odvetvia a realizáciu stavebných a montážnych projektov. Úspešne operujeme na trhoch Západnej Európy, so špeciálnym dôrazom na Nemecko.')}
                </p>
                <p>
                    {t('Náš neustály dôraz na kvalitu služieb nám pomáha posilňovať našu pozíciu a získavať dôveru našich obchodných partnerov. Naším hlavným cieľom je zabezpečiť spokojnosť našich klientov a zamestnancov, čo považujeme za kľúčový faktor úspechu a dlhodobej spolupráce.')}
                </p>
            </div>
        </div>
  )
}

export default About