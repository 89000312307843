import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const LanguageSelector = ({className}) =>
{
    const { i18n } = useTranslation("common");

    const currentLanguage = i18n.language;

    return <div className={className}>
        <Link to="/" className={` text-lg ${currentLanguage === 'sk' ? 'text-yellow-400' : 'text-white hover:text-yellow-400'}`}>SK</Link>
        <Link to="/de" className={` text-lg ${currentLanguage === 'de' ? 'text-yellow-400' : 'text-white hover:text-yellow-400'}`}>DE</Link>
    </div>
}