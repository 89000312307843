import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from './App';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_sk from "./translations/sk/common.json";
import common_de from "./translations/de/common.json";
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

const defaultLang = 'sk';

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: defaultLang,                              // language to use
    resources: {
        sk: {
            common: common_sk               // 'common' is our custom namespace
        },
        de: {
            common: common_de
        },
    },
});

function LanguageSwitcher() {
  const { lang } = useParams();
  if(lang){
    i18next.changeLanguage(lang);
  }else{
    i18next.changeLanguage(defaultLang);
  }
  return <App />;
}

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Router>
        <Routes>
          <Route path="/:lang?" element={<LanguageSwitcher />} />
        </Routes>
      </Router>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
