import React from "react";
import Slider from "react-slick";

function MultipleItems({offers}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="slider-container md:w-4/5 lg:w-3/5 w-full m-auto my-10">
      <Slider {...settings}>
        {offers.map((offer, index) => (
            <div key={index} style={{ width: "300px", height: "200px" }} className=" text-white flex flex-col justify-center text-center w-3/5">
                <span className=" text-4xl">{offer.name}</span>
                <div className=" flex justify-between mt-8">
                  <div className=" flex flex-col">
                    <span>Miesto prace</span>
                    <span>{offer.location}</span>
                  </div>
                  <div className=" flex flex-col">
                    <span>Datum nastupu</span>
                    <span>{offer.startDate}</span>
                  </div>
                  <div className=" flex flex-col">
                    <span>Druh pracovneho pomeru</span>
                    <span>{offer.offerType}</span>
                  </div>
                </div>
            </div>
        ))}
      </Slider>
    </div>
  );
}

export default MultipleItems;